import { css } from "styled-components"

import tailwindConfig from "tailwind.config"

export { fontBase, gridBreakpoints } from "tailwind.config"
export const { colors } = tailwindConfig.theme.extend

export const transitions = {
  link: css`
    transition: all 75ms ease-in-out;
  `,
}

export const breakpoints = tailwindConfig.theme.screens

export const breakpoint = (Object.keys(breakpoints) as Array<
  keyof typeof breakpoints
>).reduce(
  (acc, key) => {
    acc[key] = (style) =>
      `@media screen and (min-width: ${breakpoints[key]}) { ${style} }`
    return acc
  },
  // {} as { [index: string]: Function }
  {} as { [key in keyof typeof breakpoints]: Function }
)

export const sectionAnchorPadding = css`
  &::before {
    display: block;
    content: " ";
    margin-top: -212px;
    height: 212px;
    visibility: hidden;
    pointer-events: none;
  }
`

// Height of cards on mobile / desktop screens
export const EXPERIENCE_HEIGHT_SM = 330
export const EXPERIENCE_HEIGHT_LG = 240

export default {
  breakpoint,
  breakpoints,
}
