// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as Sentry from "@sentry/browser"
import getConfig from "next/config"
import Cookies from "js-cookie"
import { IncomingMessage } from "http"
import cache from "memory-cache"

export const AUTH_COOKIE_PATH = "Authorization"

export interface MessageWithCookies extends IncomingMessage {
  cookies?: {
    [propName: string]: string
  }
}

const { publicRuntimeConfig } = getConfig()

export const API_URL =
  publicRuntimeConfig.API_URL ||
  process.env.NEXT_PUBLIC_API_URL ||
  "http://localhost:1337"

// export const API_URL =
//   process.env.NEXT_PUBLIC_API_URL || "https://staging-admin.bunuba.com"

// export const API_URL =
//   process.env.NEXT_PUBLIC_API_URL || "https://cms.bunuba.com"

const processResponse = async (r: any) => {
  const response = r

  const data = await r.json()

  if (!response.ok) {
    let message = "Unknown error"
    if (data?.error) {
      if (Array.isArray(data?.message)) {
        message = data?.message
          ?.reduce(
            // eslint-disable-next-line @typescript-eslint/no-shadow
            (messages, message) => [
              ...messages,
              ...message.messages.map((m) => m.message),
            ],
            []
          )
          ?.join(", ")
      } else {
        message = data.message
      }
    } else if (data?.errors) {
      message = data?.errors
        ?.map(({ message: dataMessage }) => dataMessage)
        ?.join("\n")
    } else if (response?.statusText) {
      message = response?.statusText
    }
    throw (
      (data?.error && `${data.error} - ${message}`) ||
      message ||
      response?.statusText ||
      "Unknown error"
    )
  }
  return {
    data,
    response,
  }
}

export const API = {
  headers: Cookies.get(AUTH_COOKIE_PATH)
    ? {
        Authorization: `Bearer ${Cookies.get(AUTH_COOKIE_PATH)}`,
      }
    : {},
  async cachedGet(route, age = null) {
    if (typeof window !== "undefined") {
      return this.get(route)
    }
    if (cache.get(route)) {
      return cache.get(route)
    }
    const res = await this.get(route)
    cache.put(route, res, age)
    return res
  },
  async get(route: string) {
    return fetch(`${API_URL}${route}`, {
      headers: this.headers,
    }).then(processResponse)
  },
  async post(route: string, data: { [propName: string]: any } | FormData) {
    if (data instanceof FormData) {
      delete this.headers["Content-Type"]
    } else {
      this.headers["Content-Type"] = "application/json"
    }
    return fetch(`${API_URL}${route}`, {
      method: "POST",
      headers: {
        ...this.headers,
      },
      body:
        typeof window !== "undefined" && data instanceof FormData
          ? data
          : JSON.stringify(data),
    }).then(processResponse)
  },
  async put(route: string, data: { [propName: string]: any }) {
    if (data instanceof FormData) {
      delete this.headers["Content-Type"]
    } else {
      this.headers["Content-Type"] = "application/json"
    }
    return fetch(`${API_URL}${route}`, {
      method: "PUT",
      headers: {
        ...this.headers,
      },
      body:
        typeof window !== "undefined" && data instanceof FormData
          ? data
          : JSON.stringify(data),
    }).then(processResponse)
  },
  async gql(query: string, fragments = "") {
    return fetch(`${API_URL}/graphql`, {
      method: "POST",
      headers: {
        ...this.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query: `${query} ${fragments}` }),
    }).then(processResponse)
  },
  setToken(key) {
    this.headers.Authorization = `Bearer ${key}`
    return this
  },
}

export default API
