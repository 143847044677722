// nightmare file... sorry - Sam
import {
  ExperiencePage,
  LocationPage,
  UploadFile,
  GenericPage,
  StoryPage,
  ItineraryPage,
} from "lib/types"

export interface LinkButtonProps {
  id: string
  Title: string
  Url: string
  Type: "Website" | "Booking"
}

export const LinkButtonQuery = {
  component: "ComponentLinkLinkButton",
  fields: `{
    id
    Title
    Url
    Type
  }`,
}
export interface TextBlockParagraphProps {
  id?: string
  Title?: string
  Topic?: string
  Body?: string
  Anchor?: string | null
}
export const TextBlockParagraphQuery = {
  component: "ComponentTextBlockParagraph",
  fields: `{
    Anchor
    id
    Title
    Topic
    Body
  }`,
}

export interface TextBlockStoryProps {
  Title?: string
  UpperTitle?: string
  Body: string
  StoryLink: string
  Anchor: string | null
  LinkText?: string
}

export const TextBlockStoryQuery = {
  component: "ComponentTextBlockStory",
  fields: `{
    Anchor
    Title
    UpperTitle
    Body
    StoryLink
    LinkText
  }`,
}

export interface ImageTilesLocationProps {
  id: string
  Location?: string
  LocationImage?: UploadFile
  Anchor?: string | null
}

export const ImageTilesLocationQuery = {
  component: "ComponentImageTilesLocation",
  fields: `{
    Anchor
    id
    Location
    LocationImage: Image {
      url
      alternativeText
      caption
    }
  }`,
}
export interface ImageTilesItineraryProps {
  id: string
  PreTitle?: string
  Title?: string
  Content?: string
  Background?: UploadFile
  itinerary_pages?: ItineraryPage[]
  Anchor?: string | null
}

export const ImageTilesItineraryQuery = {
  component: "ComponentImageTilesItineraryImageTiles",
  fields: `{
    Anchor
    id
    Title
    PreTitle
    Content
    itinerary_pages {
      Name
      UID
      NumberOfDays
      Hero {
        BackgroundImage {
          alternativeText
          url
        }
      }
    }
    Background {
      url
      alternativeText
    }
  }`,
}

export interface TextBlockImageStoryProps {
  id: string
  Heading?: string
  LFLStoryID?: string
  UpperTitle?: string
  Title?: string
  BodyIntro?: string
  Body?: string
  PlayLink?: string
  ImageBlock?: {
    url?: string
    alternativeText?: string
  }
  BackgroundImage?: {
    url?: string
    alternativeText?: string
  }
  ImageFocusHorizontal?: "left" | "center" | "right"
  ImageFocusVertical?: "top" | "center" | "bottom"
  Illustration?: {
    url?: string
    alternativeText?: string
  }
  Anchor?: string | null
}

export const TextBlockImageStoryQuery = {
  component: "ComponentTextBlockImageStory",
  fields: `{
    Anchor
    id
    Heading
    LFLStoryID
    UpperTitle
    Title
    BodyIntro
    Body
    PlayLink
    ImageBlock: Image {
      url
      alternativeText
    }
    ImageFocusHorizontal
    ImageFocusVertical
    BackgroundImage {
      url
      alternativeText
    }
    Illustration {
      url
      alternativeText
    }
  }`,
}
export interface TextBlockImageParagraphProps {
  id: string
  Title: string
  Body: string
  LinkButton: LinkButtonProps
  _Image: {
    url: string
    alternativeText: string
    caption?: string
  }
  _ImageFocusHorizontal?: "left" | "center" | "right"
  _ImageFocusVertical?: "top" | "center" | "bottom"
  Anchor: string | null
  Reversed: boolean
  SensitiveImage?: boolean
  ImageStyle?: "cover" | "contain"
}

export const TextBlockImageParagraphQuery = {
  component: "ComponentTextBlockImageParagraph",
  fields: `{
    Anchor
    Title
    Body
    Reversed
    SensitiveImage
    ImageStyle
    _Image: Image {
      url
      alternativeText
      caption
    }
    _ImageFocusHorizontal: ImageFocusHorizontal
    _ImageFocusVertical: ImageFocusVertical
    LinkButton {
      Url
      Title
    }
  }`,
}
export interface SlidesProps {
  location_pages: LocationPage[]
  ShowButtons: boolean
}

export const SlidesQuery = {
  component: "ComponentSlidesSlides",
  fields: `{
    ShowButtons
     location_pages {
        Name
        Description
        UID
        LFLNameID
        Hero {
          Title
          BackgroundImage {
            url
            alternativeText
          }
        }
        region {
          Name
          LFLNameID
        }
      }
  }`,
}
export interface TextBlockItineraryProps {
  id: string
  Title: string
  Price: string
  Locations: LocationPage[]
  Activities: ExperiencePage[]
  Description: string
  DayImage: UploadFile
  icon: UploadFile[]
  _Link: string | null
  Anchor: string | null
}

export const TextBlockItineraryQuery = {
  component: "ComponentTextBlockItinerary",
  fields: `{
    Anchor
    id
    Title
    Price
    Locations {
      UID
      Name
      LFLNameID
    }
    Activities {
      UID
      Name
    }
    Description
    DayImage: Image {
      alternativeText
      url
    }
    icon {
      alternativeText
      url
    }
    _Link: Link   
  }`,
}
export interface TextBlockDayProps {
  id: string
  itineraries: TextBlockItineraryProps[]
  Title: string
  Day: string
  Anchor: string | null
}

export const TextBlockDayQuery = {
  component: "ComponentTextBlockDay",
  fields: `{
    id
    Anchor
    Title
    Day
    itineraries ${TextBlockItineraryQuery.fields}
  }`,
}

export interface BlockItineraryProps {
  id: string
  Title: string
  ItinerariesBlock: TextBlockItineraryProps[]
  Anchor: string | null
}

export const BlockItineraryQuery = {
  component: "ComponentBlockItinerary",
  fields: `{
    Title
    Anchor
    ItinerariesBlock ${TextBlockItineraryQuery.fields}
  }`,
}
export interface TextBlockContactProps {
  id: string
  _Contact: string
  Phone: string
  Website: string
  Email: string
  Location: string
  Anchor: string | null
}

export const TextBlockContactQuery = {
  component: "ComponentTextBlockContact",
  fields: `{
    Anchor
    id
    Phone
    Website
    Email
    Location
    _Contact: Contact
  }`,
}
export interface TitleProps {
  PreTitle: string
  Title: string
  Content: string
}

export const TitleQuery = {
  component: "ComponentTextBlockTitle",
  fields: `{
    PreTitle
    Title
    Content
  }`,
}
interface StickyNavLink {
  id: string
  Text: string
  Anchor: string | null
}

export interface StickyNavProps {
  Links: StickyNavLink[]
}

export const StickyNavQuery = {
  component: "ComponentStickyNavStickyNav",
  fields: `{
    Links {
      Text
      Anchor
    }
  }`,
}

interface PinProps {
  Name: string
  region?: {
    Name: string
  }
  Hero: {
    Latitude: number
    Longitude: number
    BackgroundImage: {
      url: string
    }
  }
}
export interface MapAProps {
  Pins: PinProps[]
}

export const MapAQuery = {
  component: "ComponentMapMapA",
  fields: `{
    Pins {
      Name
      Hero {
        Latitude
        Longitude
        BackgroundImage {
          url
        }
      }
    }
  }`,
}
export interface MapBProps {
  faded?: boolean
  PointsOfInterest: PinProps[]
}
export const MapBQuery = {
  component: "ComponentMapMapB",
  fields: `{
    PointsOfInterest {
      Name
      region {
        Name
      }
      Hero {
        Latitude
        Longitude
        BackgroundImage {
          url
        }
      }
    }
  }`,
}
interface PointOfInterestType {
  Name: string
  Content: string
  Image: {
    url: string
  }
  Latitude: number
  Longitude: number
}

export interface MapCProps {
  Longitude: number
  Latitude: number
  ShowStartMarker: boolean
  ShowEndMarker: boolean
  Overlay: {
    [propName: string]: any
  }
  Zoom: number
  PointOfInterest?: PointOfInterestType[]
}

export const MapCQuery = {
  component: "ComponentMapMapC",
  fields: `{
    Longitude
    Latitude
    Overlay
    Zoom
    ShowStartMarker
    ShowEndMarker
    PointOfInterest {
      Name
      Content
      Image {
        url
      }
      Latitude
      Longitude
    }
  }`,
}
export interface TextBlockGuideProps {
  id: string
  Title: string
  Body: string
  Image: {
    url: string
    alternativeText: string
  }[]
  Source: {
    url: string
    alternativeText: string
  }[]
  Anchor: string | null
}

export const TextBlockGuideQuery = {
  component: "ComponentTextBlockGuide",
  fields: `{
    Anchor
    id
    Title
    Body
    Image {
      alternativeText
      url
    }
    Source {
      alternativeText
      url
    }
  }`,
}
export interface BlockGuidesProps {
  id: string
  Title: string
  Guide: TextBlockGuideProps[]
  Anchor: string | null
}

export const BlockGuidesQuery = {
  component: "ComponentBlockGuides",
  fields: `{
    Title
    Anchor
    Guide ${TextBlockGuideQuery.fields}
  }`,
}
export interface ParkPassReminderProps {
  id: string
  park_pass: {
    Title?: string
    Body?: string
    LinkTitle?: string
    LinkUrl?: string
    Image?: {
      url: string
      alternativeText: string
    }
  }
}

export const ParkPassReminderQuery = {
  component: "ComponentTextBlockImageParkPassReminder",
  fields: `{
    id
    park_pass {
      Title
      Body
      Image {
        url
        alternativeText
      }
      LinkTitle
      LinkUrl
    }
  }`,
}
export interface TextBlockWithLogosProps {
  id: string
  Title: string
  Body: string
  Logos: {
    url
    alternativeText
  }[]
  Button: LinkButtonProps[]
}

export const TextBlockWithLogosQuery = {
  component: "ComponentTextBlockImageTextBlockWithLogos",
  fields: `{
    id
    Title
    Body
    Logos {
      url
      alternativeText
    }
    Button {
      ..._ComponentLinkLinkButtonFields
    }
  }`,
}
enum SeeAndDoTypes {
  EXPERIENCE = "ComponentRelationExperience",
  GENERIC = "ComponentRelationGeneric",
  STORY = "ComponentRelationStory",
}

type Experience = {
  __typename: SeeAndDoTypes.EXPERIENCE
  tile: ExperiencePage
}
type Generic = {
  __typename: SeeAndDoTypes.GENERIC
  tile: GenericPage
}
type Story = {
  __typename: SeeAndDoTypes.STORY
  tile: StoryPage
}
type SeeAndDo = Experience | Generic | Story

export type Season = {
  Name?: string
  id: string
  StartDate?: string
  EndDate?: string
  Timing?: string
  Description?: string
  SeeAndDo?: SeeAndDo[]
  BackgroundImage: UploadFile
  LFLNameID?: string
}

export interface SeasonalCalendarProps {
  id: string
  seasons?: Season[]
}
export const ExperienceTileFields = `{
  Name
  UID
  id
  PhoneNumber
  Email
  Website
  Address
  Cost
  Location {
    id
    Name
  }
  Hero {
    BackgroundImage {
      url
      alternativeText
    }
  }
}`
export const GenericTileFields = `{
  id
  Name
  UID
  page_type {
    Name
  }
  Hero {
    BackgroundImage {
      url
      alternativeText
    }
  }
}`
export const SeasonalCalendarQuery = {
  component: "ComponentCarouselSeasonalCalendar",
  fields: `{
    id
    seasons {
      id
      Name
      StartDate
      EndDate
      Timing
      Description
      LFLNameID
      BackgroundImage {
        url
        alternativeText
      }
      SeeAndDo {
        __typename
        ... on ComponentRelationExperience {
          id
          tile: experience_page ${ExperienceTileFields}
        }
        ... on ComponentRelationGeneric {
          id
          tile: generic_page ${GenericTileFields}
        }
        ... on ComponentRelationStory {
          id
          tile: story_page {
            Name
            Hero {
              Image {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }`,
}
export interface ImageTilesGenericProps {
  id?: string
  // ExperiencePages: ExperiencePage[]
  generic_pages: GenericPage[]
  Anchor?: string | null
}

export const ImageTilesGenericQuery = {
  component: "ComponentImageTilesGenericImagesTiles",
  fields: `{
    Anchor
    generic_pages ${GenericTileFields}
  }`,
}
export interface ImageTilesExperienceProps {
  id: string
  ExperiencePages: ExperiencePage[]
  Title: string
  Anchor: string | null
}

export const ImageTilesExperienceQuery = {
  component: "ComponentImageTilesExperienceImagesTiles",
  fields: `{
    id
    Anchor
    Title
    ExperiencePages ${ExperienceTileFields}
  }`,
}
export interface WayFinderProps {
  id: string
  generic_pages: GenericPage[]
  story_pages: StoryPage[]
}

export const WayFinderQuery = {
  component: "ComponentImageTilesWayFinder",
  fields: `{
    id
    generic_pages {
      id
      Name
      UID
      page_type {
        Name
      }
      Hero {
        BackgroundImage {
          url
          alternativeText
        }
      }
    }
    story_pages {
      id
      Name
      UID
      LFLNameID
      Muwayi
      Hero {
        Image {
          url
          alternativeText
        }
      }
    }
  }`,
}
export interface FilterableTilesProps {
  Title: string
  id: string
  generic_pages: GenericPage[]
}

export const FilterableTilesQuery = {
  component: "ComponentImageTilesFilterablePageTiles",
  fields: `{
    Title
    id
    generic_pages {
      id
      Name
      UID
      page_type {
        id
        Name
      }
      Hero {
        BackgroundImage {
          url
          alternativeText
        }
      }
    }
  }`,
}
export type LanguageWord = {
  LFLID: number
  BunubaPlaceholder: number
  EnglishPlaceholder: number
}

export const WordQuery = {
  component: "ComponentLanguageWord",
  fields: `{
    LFLID
    BunubaPlaceholder
    EnglishPlaceholder
  }`,
}

export interface LanguageQuoteProps {
  Quote: string
  Speaker: string
  SpeakerPosition: string
  Words: LanguageWord[]
}

export const LanguageQuoteQuery = {
  component: "ComponentLanguageLanguageQuote",
  fields: `{
    id
    Quote
    Speaker
    SpeakerPosition
    Words ${WordQuery.fields}
  }`,
}
export interface AlertProps {
  EmergencyWAParkID: string
}

export const AlertQuery = {
  component: "ComponentAlertAlert",
  fields: `{
    EmergencyWAParkID
  }`,
}

export const ProductsQuery = {
  component: "ComponentImageTilesProducts",
  fields: `{
    Title
  }`,
}
