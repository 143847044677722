import { useEffect, useRef } from "react"
import styled from "styled-components"

import { breakpoint } from "styles/Variables"

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  ${breakpoint.md`
    padding: 45px;
  `}
  &[hidden] {
    display: none;
  }
`

export const ModalCloseTapZone = styled.button.attrs(() => ({
  ariaLabel: "Click here to close",
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const ModalBox = styled.div.attrs(() => ({
  role: "dialog",
}))`
  background-color: white;
  flex-shrink: 1;
  max-width: 100%;
  max-height: 100%;
  padding: 40px 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  position: relative;
  z-index: 1;
`

export const Modal = ({
  isOpen = false,
  close,
  label = "Popup",
  removeBox = false,
  children,
  ...props
}) => {
  const closer = useRef(null)
  useEffect(() => {
    const escListener = (e) => {
      if (e.key === "Escape") {
        close()
      }
    }

    if (isOpen) {
      document.body.classList.add("fixed-scroll")
      document.body.addEventListener("keyup", escListener)
      closer?.current?.focus()
    } else {
      document.body.classList.remove("fixed-scroll")
      document.body.removeEventListener("keyup", escListener)
    }
    return () => {
      document.body.classList.remove("fixed-scroll")
      document.body.removeEventListener("keyup", escListener)
    }
  }, [close, isOpen])
  return (
    <ModalWrapper hidden={!isOpen} aria-label={label} {...props}>
      <ModalCloseTapZone onClick={close} ref={closer} />
      {removeBox ? children : <ModalBox>{children}</ModalBox>}
    </ModalWrapper>
  )
}

export default Modal
