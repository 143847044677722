import dynamic from "next/dynamic"

import useMuwayiTheme from "lib/useMuwayiTheme"

const Header = dynamic(import("components/Header"))
const Footer = dynamic(import("components/Footer"))
const SignUp = dynamic(import("components/SignUp"))

const Layout = ({ notFound = false, loginChrome = false, children }) => {
  const { hideSubscribe } = useMuwayiTheme()
  return (
    <>
      <Header notFound={notFound || loginChrome} />
      {children}
      {!loginChrome && (
        <>
          {!hideSubscribe && <SignUp />}
          <Footer />
        </>
      )}
    </>
  )
}

export default Layout
