import { createContext, useEffect, useMemo, useState } from "react"
import { gridBreakpoints } from "styles/Variables"

export const BreakpointContext = createContext({
  isLarge: false,
  isMedium: false,
})

export const BreakpointContextProvider = ({ children }) => {
  // const [isMobile, setIsMobile] = useState(true)
  // const [isMobile, setIsMobile] = useState(true)
  const [isLarge, setIsLarge] = useState(true)
  const [isMedium, setIsMedium] = useState(true)

  useEffect(() => {
    let awaitingIdle = false
    const onResize = () => {
      awaitingIdle = false

      // Checking if window is large breakpoint
      const isLargeSize = window.matchMedia(
        `(min-width: ${gridBreakpoints.lg}px)`
      )

      if (isLargeSize.matches) {
        setIsLarge(true)
      } else {
        setIsLarge(false)
      }

      // Checking if window is medium breakpoint
      const isMediumSize = window.matchMedia(
        `(min-width: ${gridBreakpoints.md}px)`
      )

      if (isMediumSize.matches) {
        setIsMedium(true)
      } else {
        setIsMedium(false)
      }
    }
    onResize()
    const resizeCaller = () => {
      if (awaitingIdle) {
        return
      }
      awaitingIdle = true
      if (window?.requestIdleCallback) {
        requestIdleCallback(onResize)
      } else {
        setTimeout(onResize, 60)
      }
    }
    window.addEventListener("resize", resizeCaller)
    return () => {
      window.removeEventListener("resize", resizeCaller)
    }
  }, [])

  const fields = useMemo(() => ({ isLarge, isMedium }), [isLarge, isMedium])

  return (
    <BreakpointContext.Provider value={fields}>
      {children}
    </BreakpointContext.Provider>
  )
}

export default BreakpointContext
