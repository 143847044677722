const gridBreakpoints = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1440,
  "2xl": 1600,
}
const fontBase = 16

module.exports = {
  gridBreakpoints,
  fontBase,
  theme: {
    screens: {
      xs: "0rem",
      sm: `${gridBreakpoints.sm / fontBase}rem`,
      md: `${gridBreakpoints.md / fontBase}rem`,
      lg: `${gridBreakpoints.lg / fontBase}rem`,
      xl: `${gridBreakpoints.xl / fontBase}rem`,
      "2xl": `${gridBreakpoints["2xl"] / fontBase}rem`,
    },
    extend: {
      spacing: {
        4.5: "1.125rem",
        5.5: "1.375rem",
        7.5: "1.875rem",
        12.5: "3.125rem",
        15: "3.75rem",
        25: "6.25rem",
        30: "7.5rem",
        50: "12.5rem",
      },
      fontFamily: {
        "sofia-pro": "sofia-pro",
      },
      colors: {
        primary: "var(--primary)",
        "primary-light": "var(--primary-light)",
        "bunuba-light": "#FAF5ED",
        "light-orange": "#F9E1B2",
        earth: "#B27651",
        black: "#000",
        white: "#fff",
        concrete: "#f3f3f3",
        "light-grey": "#E8E8E8",
        grey: "#DBDBDB",
        "med-grey": "#9B9B9B",
        "dark-grey": "#848484",
        "blue-grey": "#818B92",
        "blue-green": "#2B4049",
        "deep-blue": "#1B1E27",
        error: "#eb5757",
      },
      shadow: {
        "2xl": "0 50px 50px rgba(0,0,0,0.2)",
      },
      zIndex: {
        "-10": "-10",
      },
      screens: {
        print: { raw: "print" },
      },
    },
  },
  plugins: [],
}
