import * as Sentry from "@sentry/browser"
import { ERROR_EVENT_KEY } from "context/ToastContext"

export default function handleError(error, data = null) {
  if (typeof window === "undefined") {
    return
  }
  const errorEvent = new CustomEvent(ERROR_EVENT_KEY, {
    detail: { error, data },
  })
  window.dispatchEvent(errorEvent)

  Sentry.captureException({
    loud: true,
    error,
    data,
  })
  if (data) {
    console.error(data)
  }
}
