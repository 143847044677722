import styled, { css } from "styled-components"

import { fontBase, breakpoints } from "styles/Variables"

const Heading = css`
  font-weight: 700;
  text-decoration: none;
  font-style: normal;
  margin: 0;
`

export const H1 = styled.h1`
  ${Heading}
  font-size: ${46 / fontBase}rem;
  letter-spacing: -${2 / 46}em;
  line-height: 1;

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${80 / fontBase}rem;
    letter-spacing: -${4 / 80}em;
    line-height: 1.1;
  }
`

export const H2 = styled.h2<{ light?: boolean }>`
  ${Heading}
  font-size: ${40 / fontBase}rem;
  letter-spacing: -${1 / 40}em;
  line-height: 1.1;
  ${({ light }) =>
    light &&
    css`
      font-weight: 300;
    `}
`

export const H3 = styled.h3`
  ${Heading}
  font-size: ${24 / fontBase}rem;
  letter-spacing: -${1 / 24}em;
  line-height: 1.1;

  @media print, (min-width: ${breakpoints.lg}) {
    font-size: ${34 / fontBase}rem;
    letter-spacing: -${1 / 34}em;
  }
`

export const H4 = styled.h4<{ thin?: boolean; regular?: boolean }>`
  ${Heading}
  font-size: ${20 / fontBase}rem;
  line-height: 1.1;
  letter-spacing: -${1 / 20}em;
  ${({ thin }) =>
    thin &&
    css`
      font-weight: 200;
    `}
  ${({ regular }) =>
    regular &&
    css`
      font-weight: 400;
    `}

  @media print, (min-width: ${breakpoints.lg}) {
    font-size: ${28 / fontBase}rem;
    letter-spacing: -${1 / 28}em;
  }
`

export const H5 = styled.h5`
  ${Heading}
  font-size: ${16 / fontBase}rem;
  line-height: 1.2;
`

export const H6 = styled.h6`
  ${Heading}
  font-size: ${12 / fontBase}rem;
  letter-spacing: ${1 / 12}em;
  line-height: 1.1;
  text-transform: uppercase;

  @media print, (min-width: ${breakpoints.lg}) {
    font-size: ${14 / fontBase}rem;
    letter-spacing: ${1 / 14}em;
  }
`

export const IntroBody = styled.p`
  ${Heading}
  font-size: ${24 / fontBase}rem;
  font-weight: 300;
  letter-spacing: -${1 / 24}em;
  line-height: 1.4;

  @media (min-width: ${breakpoints.md}) {
    font-size: ${28 / fontBase}rem;
    letter-spacing: -${1 / 28}em;
  }
`

export const IntroText = styled.h2`
  ${Heading}
  font-size: ${24 / fontBase}rem;
  font-weight: 300;
  letter-spacing: -${1 / 24}em;
  line-height: 1.2;

  @media (min-width: ${breakpoints.md}) {
    font-size: ${34 / fontBase}rem;
    letter-spacing: -${1 / 34}em;
  }
`

export const BodyCopy = styled.p`
  margin: 0;
  font-size: ${16 / fontBase}rem;
  font-weight: 300;
  line-height: 1.6;

  @media (min-width: ${breakpoints.md}) {
    font-size: ${18 / fontBase}rem;
  }
`

export const BodyDetail = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: ${14 / fontBase}rem;
  line-height: 1.4;

  @media (min-width: ${breakpoints.md}) {
    font-size: ${15 / fontBase}rem;
  }
`
