import { INFO_EVENT_KEY } from "context/ToastContext"

export default function handleInfo(info) {
  if (typeof window === "undefined") {
    return
  }
  const infoEvent = new CustomEvent(INFO_EVENT_KEY, {
    detail: { info },
  })
  window.dispatchEvent(infoEvent)
}
