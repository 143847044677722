import styled from "styled-components"

import { colors } from "styles/Variables"
import { H5 } from "styles/Text"

export const PrimaryButton = styled(H5).attrs(({ as }: { as?: string }) => ({
  as: as || "a",
}))`
  background-color: ${colors.primary};
  color: ${colors.black};
  padding: 16px 22px;
  border-radius: 60px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border: none;
  display: inline-block;
  transition: background-color 0.3s;
  cursor: pointer;
  text-align: center;
  &:hover,
  &:focus {
    background-color: ${colors["primary-light"]};
  }
  &:disabled {
    background-color: ${colors["light-grey"]};
    color: ${colors["med-grey"]};
  }
`

export default PrimaryButton
