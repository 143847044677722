import { useEffect } from "react"
import Head from "next/head"
import { useRouter } from "next/router"
import * as Sentry from "@sentry/browser"
import { createGlobalStyle, ThemeProvider } from "styled-components"

import handleInfo from "lib/handleInfo"

import { BreakpointContextProvider } from "context/BreakpointContext"
import { TripContextProvider } from "context/TripContext"
import { ToastContextProvider } from "context/ToastContext"
import { TileDataContextProvider } from "context/TileDataContext"
import { MuwayiThemeContextProvider } from "context/MuwayiThemeContext"
import { AuthContextProvider } from "context/AuthContext"

import { GlobalStyle } from "styles/GlobalStyle"
import { gridBreakpoints } from "styles/Variables"

import Layout from "components/Layout"
import MetaData from "components/Metadata"
import { lambdaLoader } from "components/Image"

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn:
    process.env.SENTRY_DSN ||
    "https://f4a99a5675c843299dbebffaf481138f@o38280.ingest.sentry.io/5631020",
})

const GA_TRACKING_ID = process.env?.NEXT_PUBLIC_GA_TRACKING_ID

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const SEO = pageProps?.pageData?.SEO
  useEffect(() => {
    if (sessionStorage.getItem("app-cultural-notice")) {
      return
    }
    sessionStorage.setItem("app-cultural-notice", "shown")
    handleInfo(
      "Aboriginal and Torres Strait Islander people should be aware that this website may contain images, voices or names of deceased persons"
    )
  }, [])

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      if (!window?.gtag) {
        return
      }
      window.gtag("config", GA_TRACKING_ID, {
        page_path: url,
      })
    }
    router.events.on("routeChangeComplete", handleRouteChange)
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  const CSSVars = createGlobalStyle`
    :root {
      --primary: ${pageProps?.theme?.primaryColour || "#efb43e"};
      --primary-light: ${pageProps?.theme?.primaryLightColour || "#f3d18a"};
    }
  `

  return (
    <>
      <CSSVars />
      <GlobalStyle />
      <Head>
        <title key="title">{pageProps?.theme?.title || "Bunuba Country"}</title>
        {!GA_TRACKING_ID && <meta name="robots" content="noindex" />}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="preconnect"
          crossOrigin="crossorigin"
          href={process.env?.NEXT_PUBLIC_API_URL || "http://localhost:1337"}
        />
        {pageProps?.theme && (
          <>
            <link rel="manifest" href="/api/site.webmanifest" />
            {pageProps?.theme?.favicon && (
              <link
                rel="icon"
                href={lambdaLoader({
                  src: pageProps?.theme?.favicon?.url,
                  width: 32,
                  quality: 100,
                })}
                sizes="32x32"
              />
            )}
          </>
        )}
        {!pageProps?.theme && (
          <>
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png"
            />
            <link rel="manifest" href="/site.webmanifest" />
            <link
              rel="mask-icon"
              href="/safari-pinned-tab.svg"
              color={pageProps?.theme?.primaryColour || "#efb43e"}
            />
            <meta
              name="msapplication-TileColor"
              content={pageProps?.theme?.primaryColour || "#efb43e"}
            />
            <meta name="theme-color" content="#ffffff" />
          </>
        )}
      </Head>
      <AuthContextProvider user={pageProps?.user}>
        <MuwayiThemeContextProvider {...pageProps?.theme} {...pageProps?.nav}>
          <ThemeProvider theme={{ breakpoints: gridBreakpoints }}>
            <BreakpointContextProvider>
              <ToastContextProvider>
                <TripContextProvider>
                  <TileDataContextProvider>
                    <Layout
                      notFound={pageProps?.notFound}
                      loginChrome={pageProps?.loginChrome}
                    >
                      <Component {...pageProps} />
                    </Layout>
                  </TileDataContextProvider>
                </TripContextProvider>
              </ToastContextProvider>
            </BreakpointContextProvider>
          </ThemeProvider>
          {SEO && <MetaData {...SEO} />}
        </MuwayiThemeContextProvider>
      </AuthContextProvider>
    </>
  )
}

export default MyApp
