import { useState, useEffect } from "react"
import Link from "next/link"
import tw from "twin.macro"
import { keyframes, css } from "styled-components"
import MD from "react-markdown"

import { ToastTypes } from "context/ToastContext"
import SRText from "components/SRText"
import AlertIcon from "icons/Alert"
import CloseIcon from "icons/Close"

const shiftUp = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0)
  }
`

const TOAST_LIFETIME = 6500

export const ToastMessage = ({ content, type, id, close }) => {
  const [hasInteracted, setHasInteracted] = useState(false)

  // Close the toast after a period of time if not interacted with
  useEffect(() => {
    const autoClose = setTimeout(() => {
      if (hasInteracted) {
        return
      }
      close(id)
    }, TOAST_LIFETIME)
    return () => {
      clearTimeout(autoClose)
    }
  }, [close, hasInteracted, id])

  return (
    <div
      tw="bg-primary max-w-full text-black flex p-5 rounded mt-5 font-semibold pointer-events-auto"
      role="alert"
      css={css`
        width: 615px;
        animation: ${shiftUp} 0.3s ease;
      `}
      onMouseOver={() => setHasInteracted(true)}
      onFocus={() => setHasInteracted(true)}
    >
      {type === ToastTypes.ALERT && <AlertIcon tw="mr-3 mt-1 flex-shrink-0" />}
      <div tw="flex-grow">
        <MD
          unwrapDisallowed
          disallowedTypes={["paragraph"]}
          renderers={{
            link: ({ children, ...props }) => (
              <Link {...props} passHref>
                <a>{children}</a>
              </Link>
            ),
          }}
        >
          {content}
        </MD>
      </div>
      <button
        type="button"
        onClick={() => close(id)}
        tw="ml-3 w-11 h-11 -my-2 -mr-4 flex-shrink-0 cursor-pointer"
      >
        <SRText>Close {type === ToastTypes.ALERT ? "alert" : "message"}</SRText>
        <CloseIcon tw="w-3 h-3" />
      </button>
    </div>
  )
}

export default ToastMessage
