import { createContext, useState, useEffect, useCallback, useMemo } from "react"
import tw from "twin.macro"

import Toast from "components/Toast"

export enum ToastTypes {
  INFO = "info",
  ALERT = "alert",
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const ToastContext = createContext({
  addToast: (content, type = ToastTypes.INFO) => {},
})
/* eslint-enable @typescript-eslint/no-unused-vars */
export default ToastContext

export const ERROR_EVENT_KEY = "app-global-error"
export const INFO_EVENT_KEY = "app-global-info"

type ToastMember = {
  content: string
  id: string
  type: ToastTypes
}

export const ToastContextProvider = ({ children }) => {
  const [toasts, setToasts] = useState<ToastMember[]>([])

  const addToast = useCallback((content: string, type = ToastTypes.INFO) => {
    setToasts((t) => [...t, { content, type, id: `${Date.now()}` }])
  }, [])

  // Set up event listeners so that we can programatically add a toast without needing useContext
  useEffect(() => {
    const errorListener = (e: CustomEvent) => {
      e.stopPropagation()
      addToast(e.detail.error, ToastTypes.ALERT)
    }
    const infoListener = (e: CustomEvent) => {
      e.stopPropagation()
      addToast(e.detail.info, ToastTypes.INFO)
    }
    window.addEventListener(ERROR_EVENT_KEY, errorListener)
    window.addEventListener(INFO_EVENT_KEY, infoListener)
    return () => {
      window.removeEventListener(ERROR_EVENT_KEY, errorListener)
      window.removeEventListener(INFO_EVENT_KEY, infoListener)
    }
  }, [addToast])

  const closeToast = useCallback((id: string) => {
    setToasts((tx) => tx.filter((t) => t.id !== id))
  }, [])

  const fields = useMemo(() => ({ addToast }), [addToast])

  return (
    <ToastContext.Provider value={fields}>
      {children}
      {toasts.length > 0 && (
        <div tw="fixed bottom-4 right-4 left-4 z-50 flex flex-col items-end pointer-events-none">
          {toasts.map(({ content, type, id }) => (
            <Toast
              key={id}
              content={content}
              type={type}
              close={closeToast}
              id={id}
            />
          ))}
        </div>
      )}
    </ToastContext.Provider>
  )
}
