import Cookies from "js-cookie"
import {
  useEffect,
  useCallback,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react"

import { API, API_URL, AUTH_COOKIE_PATH } from "lib/api"
import { useRouter } from "next/router"

export type User = {
  id: number
  email: string
  username: string
  name: string
}

const ctxSetState: Dispatch<SetStateAction<User>> = (s) => s

const AUTH_COOKIE_DOMAIN_PATH = "Authorization-Domain"

export const AuthContext = createContext({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  user: null as User,
  setUser: ctxSetState,
  login: async (form, persistent = false) => ({}),
  logout: async () => {},
  canView: (pageUserGroups) => !pageUserGroups,
  /* eslint-enable @typescript-eslint/no-unused-vars */
})

export const AuthContextProvider = ({ user: _user, children }) => {
  const [user, setUser] = useState(_user)
  const router = useRouter()

  const validateToken = useCallback(async () => {
    const { data } = await API.get("/users/me")
    return data
  }, [])

  useEffect(() => {
    if (!Cookies.get(AUTH_COOKIE_PATH) || _user) {
      return
    }
    validateToken()
      .then((u) => {
        setUser(u)
      })
      .catch(() => {
        console.info("ℹ️ Cleared expired Auth cookie")
        Cookies.remove(AUTH_COOKIE_PATH)
        Cookies.remove(AUTH_COOKIE_DOMAIN_PATH)
        API.headers.Authorization = undefined
      })
  }, [validateToken, _user])

  const login = useCallback(async (form, persistent = false) => {
    const {
      data: { jwt, user: __user },
    } = await API.post("/auth/local", form)
    Cookies.set(AUTH_COOKIE_PATH, jwt, {
      sameSite: "strict",
      expires: persistent ? 90 : null,
    })
    Cookies.set(AUTH_COOKIE_DOMAIN_PATH, API_URL, {
      sameSite: "strict",
      expires: persistent ? 90 : null,
    })
    API.setToken(jwt)
    setUser(__user)
    return __user
  }, [])

  const logout = useCallback(async () => {
    Cookies.remove(AUTH_COOKIE_PATH)
    Cookies.remove(AUTH_COOKIE_DOMAIN_PATH)
    API.headers.Authorization = undefined
    // Wait until the new page has loaded before unsetting the user in case the page relies on user data
    function resetUser() {
      setUser(null)
      router.events.off("routeChangeComplete", this)
    }
    router.events.on("routeChangeComplete", resetUser)
    router.reload()
  }, [router])

  const canView = useCallback(
    (pageUserGroups) => {
      if ((pageUserGroups?.length || 0) < 1) {
        return true
      }
      try {
        const userGroupIDs = (user?.user_groups || [])?.map(({ id }) => id)
        return pageUserGroups
          .map(({ id }) => id)
          .some((id) => userGroupIDs.includes(id))
      } catch (e) {
        return false
      }
    },
    [user]
  )

  const fields = { user, setUser, login, logout, canView }

  return <AuthContext.Provider value={fields}>{children}</AuthContext.Provider>
}
export default AuthContext
