import useMuwayiTheme from "lib/useMuwayiTheme"
import Head from "next/head"

interface MetaDataProps {
  Title?: string
  Description?: string
  raw?: boolean
}

export const MetaFields = `
  SEO {
    Title
    Description
  }
`

export const MetaData = ({
  Title,
  Description,
  raw = false,
}: MetaDataProps) => {
  const { title } = useMuwayiTheme()
  return (
    <Head>
      {Title && (
        <title key="title">
          {Title}
          {!raw ? [" -", title || "Bunuba Country"].join(" ") : ""}
        </title>
      )}
      {Description && (
        <meta name="description" key="description" content={Description} />
      )}
    </Head>
  )
}

MetaData.defaultProps = {
  Title: null,
  Description: null,
  raw: false,
}
export default MetaData
