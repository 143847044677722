import { NavigationItem } from "components/Header/Navigation"
import { createContext } from "react"

export const MuwayiThemeContext = createContext({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  title: "Bunuba Country",
  primaryColour: "#efb43e",
  primaryLightColour: "#f3d18a",
  logo: null,
  countryImage: null,
  showLogin: false,
  PrimaryMenu: [] as NavigationItem[],
  CalloutMenu: [],
  FooterMenu: [],
  FooterLogoMenu: [],
  SocialMenu: [],
  CalloutButton: { label: "", link: "", theme: "primary" },
  hideAddToTrip: false,
  hideSubscribe: false,
  /* eslint-enable @typescript-eslint/no-unused-vars */
})

export const MuwayiThemeContextProvider = ({
  title,
  primaryColour,
  primaryLightColour,
  logo,
  showLogin = false,
  PrimaryMenu,
  CalloutMenu,
  FooterMenu,
  FooterLogoMenu,
  SocialMenu,
  CalloutButton,
  countryImage,
  hideAddToTrip = false,
  hideSubscribe = false,
  children,
}) => {
  const fields = {
    title,
    primaryColour,
    primaryLightColour,
    logo,
    PrimaryMenu,
    CalloutMenu,
    FooterMenu,
    FooterLogoMenu,
    SocialMenu,
    CalloutButton,
    showLogin,
    countryImage,
    hideAddToTrip,
    hideSubscribe,
  }

  return (
    <MuwayiThemeContext.Provider value={fields}>
      {children}
    </MuwayiThemeContext.Provider>
  )
}
export default MuwayiThemeContext
