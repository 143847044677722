import { createContext, useContext, useState, useEffect } from "react"
import { useRouter } from "next/router"
import tw from "twin.macro"

import API from "lib/api"
import handleError from "lib/handleError"
import ToastContext from "context/ToastContext"
import { ExperienceTileFields } from "components/dynamic"
import Modal from "components/Modal"
import { H4 } from "styles/Text"
import PrimaryButton from "components/Buttons/Primary"

type Experience = {
  id: string
  Name: string
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const TripContext = createContext({
  experiences: [[]],
  addExperience: (experience) => {},
  removeExperience: (id) => {},
  setExperiences: (experiences) => {},
  ready: false,
})
/* eslint-enable @typescript-eslint/no-unused-vars */
export default TripContext

const experienceStorageKey = "bunuba-experiences"

const fetchExperiences = async (experienceDayIds) => {
  const allIds = experienceDayIds.reduce((acc, day) => [...acc, ...day], [])
  const { data } = await API.gql(`
    {
      experiencePages(where: {id_in: ${JSON.stringify(
        allIds
      )}}) ${ExperienceTileFields}
    }
  `)
  const experiences = data?.data?.experiencePages
  if (!experiences) {
    throw "No experiences received"
  }
  const sortedExperiences = experienceDayIds.map((day) =>
    day.map((id) => experiences.find((xp) => xp.id === id))
  )

  return sortedExperiences
}

export const TripContextProvider = ({ children }) => {
  const router = useRouter()
  const [experiences, setExperiences] = useState<null | Experience[][]>(null)
  const [experiencesReady, setExperiencesReady] = useState(false)
  const [isMergeModalOpen, setIsMergeModalOpen] = useState(false)
  const { addToast } = useContext(ToastContext)

  // Load favourited experiences that are set in storage
  useEffect(() => {
    if (experiences !== null || experiencesReady) {
      return
    }
    const query = decodeURIComponent(window.location.search)
      .slice(1)
      .split("&")
      .reduce((q, c) => {
        const [k, v] = c.split("=")
        return {
          ...q,
          [k]: v,
        }
      }, {} as { [propName: string]: string })
    const sharedExperiences =
      query?.experiences && JSON.parse(query?.experiences)
    const storedExperiences =
      localStorage.getItem(experienceStorageKey) &&
      JSON.parse(localStorage.getItem(experienceStorageKey))

    if (!storedExperiences && !sharedExperiences) {
      setExperiences([[]])
      setExperiencesReady(true)
      return
    }
    if (storedExperiences && sharedExperiences) {
      setIsMergeModalOpen(true)
      return
    }
    fetchExperiences(storedExperiences || sharedExperiences)
      .then((sortedExperiences) => setExperiences(sortedExperiences))
      .catch((e) => handleError("Error fetching stored experiences", e))
      .finally(() => setExperiencesReady(true))
    if (window.location.search && sharedExperiences) {
      router.replace(window.location.pathname, window.location.pathname, {
        shallow: true,
      })
    }
  }, [experiences, experiencesReady, router, isMergeModalOpen])

  // Update localStorage for persistence
  useEffect(() => {
    if (typeof window === "undefined" || experiences === null) {
      return
    }
    localStorage.setItem(
      experienceStorageKey,
      JSON.stringify(experiences.map((day) => day.map((xp) => xp?.id)))
    )
  }, [experiences])

  const addExperience = (experience: Experience) => {
    const newExperiences = experiences.reduce(
      (acc: Experience[][], day: Experience[], idx) => {
        if (idx === experiences.length - 1) {
          return [...acc, [...day, experience]]
        }
        return [...acc, day]
      },
      []
    )
    addToast(`${experience?.Name || "Experience"} added to [My Trip](/my-trip)`)
    setExperiences(newExperiences)
  }

  const removeExperience = (id) => {
    const newExperiences = experiences.map((day) =>
      day.filter((xp) => xp.id !== id)
    )
    setExperiences(newExperiences)
  }

  return (
    <TripContext.Provider
      value={{
        experiences: experiences || [[]],
        addExperience,
        removeExperience,
        setExperiences,
        ready: experiencesReady,
      }}
    >
      {children}
      {isMergeModalOpen && (
        <Modal isOpen={isMergeModalOpen} close={() => {}}>
          <div tw="flex-grow flex flex-col justify-center text-center items-center w-96">
            <H4 thin as="h2" tw="mb-5">
              Would you like to load this shared trip or keep your trip?
            </H4>
            <PrimaryButton
              as="button"
              type="button"
              onClick={() => {
                localStorage.removeItem(experienceStorageKey)
                setIsMergeModalOpen(false)
              }}
              tw="w-64"
            >
              Load shared trip
            </PrimaryButton>
            <div>
              <button
                type="button"
                tw="text-dark-grey p-3 mt-2 w-64 cursor-pointer"
                onClick={() => {
                  router.replace(window.location.pathname)
                  setIsMergeModalOpen(false)
                }}
              >
                Keep your trip
              </button>
            </div>
          </div>
        </Modal>
      )}
    </TripContext.Provider>
  )
}
