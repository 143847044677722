import { createContext, useState, useRef, useCallback } from "react"
import tw from "twin.macro"

import API from "lib/api"
import { GenericPage, ExperiencePage, StoryPage } from "lib/types"

/* eslint-disable @typescript-eslint/no-unused-vars */
const TileDataContext = createContext({
  getGenericPage: (id) => new Promise<GenericPage>(() => {}),
  getExperiencePage: (id) => new Promise<ExperiencePage>(() => {}),
  getStoryPage: (id) => new Promise<StoryPage>(() => {}),
})
/* eslint-enable @typescript-eslint/no-unused-vars */
export default TileDataContext

// This context is built because Strapi has a maximum GQL depth that means
// some tiles don't get image data. This context provides a path for tiles
// to load up the additional data they were missing across a page without
// duplicating requests. It's a bit of a nightmare, but it seems to work.
export const TileDataContextProvider = ({ children }) => {
  const genericPagePromises = useRef({})
  const [genericPages, setGenericPages] = useState({})
  const experiencePagePromises = useRef({})
  const [experiencePages, setExperiencePages] = useState({})
  const storyPagePromises = useRef({})
  const [storyPages, setStoryPages] = useState({})

  const getGenericPage = useCallback(
    (id: string | number) => {
      if (!genericPagePromises.current?.[id]) {
        genericPagePromises.current[id] = new Promise<GenericPage>(
          (resolve, reject) => {
            if (genericPages[id]) {
              resolve(genericPages[id])
            }
            API.gql(
              `{
              genericPages(where:{${
                typeof id === "number" ? "id" : "UID"
              }: ${JSON.stringify(id)}}) {
                page_type {
                  Name
                }
                Hero {
                  BackgroundImage {
                    url
                    alternativeText
                  }
                }
              }
            }`
            )
              .then(({ data }) => {
                setGenericPages({
                  ...genericPages,
                  [id]: data.data.genericPages[0],
                })
                resolve(data.data.genericPages[0])
              })
              .catch((e) => {
                reject(e)
              })
          }
        )
      }
      return genericPagePromises.current[id]
    },
    [genericPages]
  )

  const getExperiencePage = useCallback(
    (id) => {
      if (!experiencePagePromises.current?.[id]) {
        experiencePagePromises.current[id] = new Promise<ExperiencePage>(
          (resolve, reject) => {
            if (experiencePages[id]) {
              resolve(experiencePages[id])
            }
            API.get(`/experience-pages/${id}`)
              .then(({ data }) => {
                setExperiencePages({
                  ...experiencePages,
                  [id]: data,
                })
                resolve(data)
              })
              .catch((e) => {
                reject(e)
              })
          }
        )
      }
      return experiencePagePromises.current[id]
    },
    [experiencePages]
  )

  const getStoryPage = useCallback(
    (id) => {
      if (!experiencePagePromises.current?.[id]) {
        experiencePagePromises.current[id] = new Promise<StoryPage>(
          (resolve, reject) => {
            if (storyPages[id]) {
              resolve(storyPages[id])
            }
            API.get(`/story-pages/${id}`)
              .then(({ data }) => {
                setStoryPages({
                  ...storyPages,
                  [id]: data,
                })
                resolve(data)
              })
              .catch((e) => {
                reject(e)
              })
          }
        )
      }
      return storyPagePromises.current[id]
    },
    [storyPages]
  )

  return (
    <TileDataContext.Provider
      value={{
        getGenericPage,
        getExperiencePage,
        getStoryPage,
      }}
    >
      {children}
    </TileDataContext.Provider>
  )
}
